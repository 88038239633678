<template>
  <div class="facet__value">
    <div
      class="facet__value-header"
      :class="{
        'is-open': facetValue.isOpen
      }"
    >
      <div class="facet__value-title checkbox">
        <input
          :id="facetValue.indexTitle"
          :checked="facetValue.isRefined"
          :disabled="!facetValue.valueQty && !facetValue.isRefined"
          type="checkbox"
          class="checkbox__input"
        />
        <label
          @click.stop.prevent="!facetValue.valueQty && !facetValue.isRefined ? null : $emit('valueChecked', facetValue)"
          :for="facetValue.indexTitle"
          class="checkbox__label"
          :class="{disabled: !facetValue.valueQty}"
        >
          <span class="facet__value-label">{{ facetValue.valueTitle }}</span>
          <span v-if="!facetValue.visibleSubValues" class="facet__value-qty">{{ facetValue.valueQty }}</span>
        </label>
      </div>
      <button
        v-if="facetValue.visibleSubValues"
        @click="facetValue.isOpen = !facetValue.isOpen"
        class="button button--round"
      >
        <i class="icon-angle-right"></i>
      </button>
    </div>
    <div
      v-if="facetValue.isOpen && facetValue.visibleSubValues"
      class="facet__value-list"
      :class="{
        'is-open': facetValue.isOpen
      }"
    >
      <search-facet-value
        v-for="subValue in facetValue.visibleSubValues.sort((a, b) => !!b.visibleSubValues - !!a.visibleSubValues || b.valueQty - a.valueQty)"
        v-on="$listeners"
        :key="subValue.indexTitle"
        :facetValue="subValue"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchFacetValue',
  props: ['facetValue']
}
</script>
