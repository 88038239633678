<template>
  <div class="search__facets">
    <ul class="search__facets-list">
      <search-facet-group
        v-for="facet in visibleFacets.filter(facet => facet.isSticky)"
        :key="facet.facetTitle"
        :facet="facet"
        v-on="$listeners"
      />
      <search-facet-group
        v-for="facet in visibleFacets.filter(facet => !facet.isSticky)"
        :key="facet.facetTitle"
        :facet="facet"
        v-on="$listeners"
      />
    </ul>
    <button
      v-if="searchFacets.length > facetsToShowNum"
      @click="isFacetListFull = !isFacetListFull"
      v-html="showMoreLessText"
      type="button"
      class="button button--outlined"
    ></button>
  </div>
</template>

<script>
import SearchFacetGroup from '@/components/search/SearchFacetGroup'

export default {
  name: 'SearchFacets',
  components: {
    SearchFacetGroup
  },
  props: ['searchFacets'],
  computed: {
    visibleFacets () {
      return this.isFacetListFull ? this.searchFacets : this.searchFacets.slice(0, this.facetsToShowNum)
    },
    showMoreLessText () {
      return this.isFacetListFull ? 'Show less filters' : 'Show more filters'
    }
  },
  created () {
    if (this.$route.query.showAllFacets === 'true') this.isFacetListFull = true
    this.$watch('isFacetListFull', () => {
      this.$router.replace({ query: { ...this.$route.query, showAllFacets: this.isFacetListFull }, params: { key: this.$route.path } })
    })
  },
  data () {
    return {
      isFacetListFull: false,
      facetsToShowNum: 15
    }
  }
}
</script>
