<template>
  <section class="search__grid">
    <div class="search__grid-banner" v-if="isSearchResultsAvailable && showBannerAd && bannerAdToShow">
      <Banner
        :data="bannerAdToShow"
        :parentKey="parentKey"
        buttonStyle="primary"
      />
    </div>
    <ul
      v-infinite-scroll="triggerLoadMore"
      infinite-scroll-distance="10"
      infinite-scroll-disabled="isLoaderVisible"
      v-show="isSearchResultsAvailable"
      class="search__grid-results"
    >
      <li
        v-for="(item, idx) in searchResults"
        :key="item.title + idx"
        class="search__grid-item"
      >
        <SearchItem
          v-on="$listeners"
          @addProductToCart="onAddToCartClick(item, idx, $event)"
          :productData="item"
          :index="idx"
          :showItemCategories="showItemCategories"
          :isComparable="true"
          :populateAnalytics="true"
          :isCatalog="isCatalog"
          :catalogOriginalTitle="catalogOriginalTitle"
          :searchQuery="searchQuery"
        />
      </li>
    </ul>
    <template v-if="!isSearchResultsAvailable">
      <h2 class="search__grid-noresults">{{ `No results for direct purchase found for search ${isCatalog ? '' : `'${searchQuery}'`} on Hubbell Store` }}</h2>
      <div v-if="suggestedProducts && suggestedProducts.length">
        <div class="search__grid-suggested-message">
          <p class="search__grid-suggested-message-line">Listed below are similar products from Hubbell:</p>
          <a
            :href="externalSearchLink"
            target="_blank"
            class="link"
          >Click here to search '{{searchQuery}}'</a>
          across all Hubbell brands and view product details on Hubbell.com
        </div>
        <div class="search__grid-suggested-items">
          <div
            v-for="(suggestedProduct, idx) in suggestedProducts"
            :key="suggestedProduct.title + idx"
            class="search__grid-suggested-item"
          >
            <SearchItem
              :productData="suggestedProduct"
              :index="idx"
              :showFavoritesIcon="false"
            />
          </div>
        </div>
      </div>
    </template>

    <ModalCart
      v-if="showModalCart"
      @close="closeCartModal"
      @addProduct="onAddToCartFromModalCart"
      :isLoading="isModalCartLoading"
      :productEntry="productEntryData"
      :showModalCart="showModalCart"
    />

    <ModalInventory
      @close="closeInventoryModal"
      :inventoryData="inventoryData"
      :showModal="showModalInventory"
    />
  </section>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
import { mapState } from 'vuex'

import settings from '@/settings'
import AddToCartMixin from '@/mixins/AddToCartMixin'
import SearchItem from '@/components/search/SearchItem'
import ModalCart from '@/components/modal/ModalCart'
import ModalInventory from '@/components/modal/ModalInventory'
import Banner from '@/components/Banner'

export default {
  name: 'SearchGrid',
  components: {
    SearchItem,
    ModalCart,
    ModalInventory,
    Banner
  },
  mixins: [AddToCartMixin],
  props: [
    'searchResults',
    'nextPage',
    'hasClearanceItems',
    'isClearanceApplied',
    'searchQuery',
    'showItemCategories',
    'suggestedProducts',
    'isCatalog',
    'isLoaderVisible',
    'bannerAd',
    'showBannerAd',
    'catalogOriginalTitle',
    'parentKey'
  ],
  directives: { infiniteScroll },
  computed: {
    ...mapState('search', ['resultsMetaData']),
    ...mapState('content', ['defaultBannerAd']),
    isSearchResultsAvailable () {
      return !!(this.searchResults && this.searchResults.length)
    },
    externalSearchLink () {
      return `https://www.hubbell.com/${settings.brandNamesByCountry[settings.app.country]}/en/search2?searchQuery=${this.searchQuery}`
    },
    bannerAdToShow () {
      return this.bannerAd ? this.bannerAd : this.defaultBannerAd
    }
  },
  methods: {
    onAddToCartClick (productData, index, addToCartQty) {
      this.$emit('loaderToggled')
      return this.addProductToCart({
        productData,
        addToCartQty,
        position: index + 1,
        pageName: this.isCatalog ? 'Category' : 'Search Results',
        searchQuery: this.isCatalog ? '' : this.searchQuery,
        indexName: this.resultsMetaData?.indexName,
        queryID: this.resultsMetaData?.queryID,
        catalogOriginalTitle: this.catalogOriginalTitle
      }).finally(() => {
        this.$emit('loaderToggled')
      })
    },
    onAddToCartFromModalCart ({ productData, addToCartQty }) {
      this.isModalCartLoading = true
      return this.addProductToCart({
        productData,
        addToCartQty,
        position: 0,
        pageName: 'Related Products Add to Cart',
        searchQuery: '',
        enable: true
      }).finally(() => {
        this.isModalCartLoading = false
      })
    },
    triggerLoadMore () {
      if (this.nextPage) {
        this.$emit('loaderToggled')
        this.$emit('loadMoreClicked')
      }
    }
  },
  data () {
    return {
      isModalCartLoading: false
    }
  }
}
</script>
