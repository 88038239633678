<template>
  <ul class="search__chips">
      <li v-if="facetValues && facetValues.length">
        <button @click="$emit('facetsReset')" class="search__chips-clear-all button button--outlined">Clear All</button>
      </li>
      <li class="search__chips-item" v-if="searchQuery">
        Search: {{ searchQuery }}
        <i
          class="icon-times search__chips-close"
          @click="$emit('queryReset')"
        />
      </li>
      <template v-if="facetValues && facetValues.length">
        <li
          v-for="facetValue in facetValues"
          :key="facetValue.indexTitle"
          class="search__chips-item"
        >
          {{ facetValue.facetDisplayTitle }}:
          {{ facetValue.valueTitle }}
          <i class="icon-times search__chips-close" @click="$emit('facetReset', facetValue)" />
        </li>
      </template>
    </ul>
</template>

<script>
export default {
  name: 'SearchFacetsChips',
  props: ['searchQuery', 'facetValues', 'isClearanceApplied']
}
</script>
