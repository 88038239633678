<template>
  <section class="search container">
    <template v-if="resultsMetaData">
      <div v-if="showSearchQueryInput" v-click-outside="onQueryInputBlur" class="search__input-block">
        <div class="search__input">
          <input
            :value="searchQuery"
            @input="handleQueryInput"
            @focus="onQueryInputFocus"
            type="search"
            placeholder="Search product number or keyword"
            class="input"
          />
        </div>
        <div class="search__suggestions-wrap" v-if="showSuggestions">
          <SearchSuggestions
            @suggestionSelected="onSuggestionSelected"
            class="search__suggestions"
          />
        </div>
      </div>
      <p
        v-if="isMobile && resultsMetaData && resultsMetaData.totalQty"
        class="search__header-subtitle"
      >
        {{ resultsMetaData.totalQty }} Products
      </p>
      <div v-if="isMobile && visibleSearchResults && visibleSearchResults.length" class="search__filter-btn">
        <button
          @click="toggleSearchFilters"
          type="button"
          class="button button--primary-black"
        >
          {{ isSearchFiltersVisible ? "HIDE FILTERS" : "FILTERS" }}
        </button>
      </div>
      <div v-if="isSearchFiltersVisible" class="search__header-wrapper">
        <search-chips
          v-if="searchQuery || facetChips.length || isClearanceApplied"
          @queryReset="onSearchQueryReset"
          @facetReset="onFacetValueUpdate"
          @facetsReset="onFacetsReset"
          :searchQuery="searchQuery"
          :facetValues="facetChips"
          :isClearanceApplied="isClearanceApplied"
        />
        <div class="search__header">
          <div>
            <p
              class="search__header-subtitle"
              v-if="!isMobile && resultsMetaData && resultsMetaData.totalQty"
            >
              {{ resultsMetaData.totalQty }} Products
            </p>
          </div>
          <div class="search__header-right">
            <FormToggle
              v-if="isEmployee"
              v-model="employeeToggle.value"
              :field="employeeToggle"
              :isLabelBeforeToggle="true"
              class="search__header-employee-toggle"
            />
            <div
              class="select__container"
              v-if="resultsMetaData && resultsMetaData.totalQty"
            >
              <label for="sortingSelect" class="select__label">Sort by</label>
              <select
                v-model="selectedSorting"
                @change="onSortingUpdate"
                name="sortingSelect"
                class="select search__sorting"
              >
                <option value="relevance">Relevance</option>
                <option value="popularity">Popularity</option>
                <option value="price">List Price (low to high)</option>
                <option value="price_desc">List Price (high to low)</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="search__body">
        <div
          v-if="visibleSearchFacets && isSearchFiltersVisible && visibleSearchResults && visibleSearchResults.length"
          :style="isIE ? { top: `${getHeaderHeight() + 20}px` } : null"
          class="search__facets-wrap column is-one-quarter"
          :class="{ sticky: isIE && emulateStickyBehavior }"
        >
          <search-facets
            @valueChecked="onFacetValueUpdate"
            @facetToggled="closeFacets"
            @clearFacetSelections="onSingleFacetReset"
            :searchFacets="visibleSearchFacets"
          ></search-facets>
        </div>
        <search-grid
          @loadMoreClicked="doSearchMore"
          @loaderToggled="isLoading = !isLoading"
          @itemCategoryClicked="onItemCategoryClick"
          :searchResults="visibleSearchResults"
          :nextPage="nextPage"
          :hasClearanceItems="hasClearanceItems"
          :isClearanceApplied="isClearanceApplied"
          :searchQuery="searchQuery"
          :showItemCategories="showItemCategories"
          :suggestedProducts="suggestedProductsData"
          :isCatalog="isCatalog"
          :catalogOriginalTitle="catalogOriginalTitle"
          :isLoaderVisible="isLoading || !isInitialized"
          :bannerAd="bannerAd"
          :showBannerAd="showBannerAd"
          :parentKey="parentKey"
          class="column"
          :class="{ 'is-three-quarters': visibleSearchFacets && visibleSearchResults && visibleSearchResults.length }"
        ></search-grid>
        <loader v-if="isLoading" />
      </div>
    </template>
    <loader v-else />

    <ProductsCompareOverlay />
  </section>
</template>

<script>
import _ from 'lodash'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

import UtilityMixin from '@/mixins/UtilityMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import NavigationalMixin from '@/mixins/NavigationalMixin'
import SearchChips from '@/components/search/SearchChips'
import SearchFacets from '@/components/search/SearchFacets.vue'
import SearchGrid from '@/components/search/SearchGrid.vue'
import FormToggle from '@/components/FormToggle'
import ProductsCompareOverlay from '@/components/ProductsCompareOverlay'
import SearchSuggestions from '@/components/search/SearchSuggestions'

export default {
  name: 'SearchComponent',
  components: {
    SearchFacets,
    SearchGrid,
    SearchChips,
    FormToggle,
    ProductsCompareOverlay,
    SearchSuggestions
  },
  mixins: [UtilityMixin, LoaderMixin, ScreenWidthMixin, NavigationalMixin],
  props: {
    showSearchQueryInput: {
      type: Boolean,
      required: false,
      default: true
    },
    queryToApply: {
      type: String,
      required: false
    },
    facetValuesToApply: {
      type: Array,
      required: false,
      default: () => []
    },
    facetsToHide: {
      type: Array,
      required: false,
      default: () => []
    },
    showAppliedFacetChips: {
      type: Boolean,
      required: false,
      default: false
    },
    showItemCategories: {
      type: Boolean,
      required: false,
      default: true
    },
    defaultSorting: {
      type: String,
      default: 'popularity'
    },
    showInitiallyEmptyFacets: {
      type: Boolean,
      default: true
    },
    isCatalog: {
      type: Boolean,
      default: false
    },
    bannerAd: {
      type: Object,
      required: false
    },
    showBannerAd: Boolean,
    catalogOriginalTitle: {
      type: String,
      default: ''
    },
    parentKey: {
      type: String,
      required: true,
      default: ''
    }
  },
  watch: {
    initFacetsData: {
      immediate: true,
      handler (facets) {
        if (!facets) return
        const nestedItems = []
        const facetsToHide = [...this.facetsToHide, ...this.facetBlackList]
        const grouped = Object.keys(facets)
          .map(facet => {
            const facetType = facet.includes('.lvl') ? 'nested' : 'flat'
            const facetItem = {
              facetTitle: facet,
              displayTitle: facet.split('.lvl')[0],
              facetType: facetType,
              isSticky: facetType === 'nested',
              isSubFacet: !!+facet.split('.lvl')[1],
              isOpen: facetType === 'nested',
              rootGroup: facet.split('.lvl')[0],
              get isVisible () {
                return (
                  !this.isSubFacet &&
                  this.facetValues.some(el => el.isVisible) &&
                  !facetsToHide.includes(this.rootGroup) &&
                  this.facetValues.some(el => el.valueQty)
                )
              },
              get visibleFacetValues () {
                return this.facetValues.filter(el => el.isVisible).length
                  ? this.facetValues.filter(el => el.isVisible)
                  : null
              },
              get totalValueQty () {
                return this.visibleFacetValues
                  ? this.visibleFacetValues.reduce(
                    (acc, { valueQty }) => acc + valueQty,
                    0
                  )
                  : 0
              },
              facetValues: Object.keys(facets[facet])
                .map(value => {
                  const facetTitle = value
                    .split(' > ')
                    .slice(-1)
                    .pop()
                  return {
                    valueTitle: facetTitle,
                    indexTitle: `'${facet.replace(/'/g, "\\'")}':'${value.replace(/'/g, "\\'")}'`,
                    originalTitle: value,
                    valueQty: facets[facet][value],
                    isRefined: false,
                    isOpen: false,
                    facetDisplayTitle: facet.split('.lvl')[0],
                    get facet () {
                      return facetItem
                    },
                    isVisible: true,
                    get visibleSubValues () {
                      return this.subValues?.filter(el => el.isVisible).length
                        ? this.subValues?.filter(el => el.isVisible)
                        : null
                    }
                  }
                })
                .sort((a, b) => a.valueTitle.localeCompare(b.valueTitle, 'en', { numeric: true }))
            }
            if (facetType === 'nested') nestedItems.push(facetItem)
            return facetItem
          })
          .sort((a, b) => b.totalValueQty - a.totalValueQty)

        // @TODO refactor the tree constructor
        nestedItems.forEach(facet => {
          const [nestTitle, nestIdxStr] = facet.facetTitle.split('.lvl')
          const nestIdxInt = parseInt(nestIdxStr)
          if (nestIdxInt) {
            const parentFacet = nestedItems.find(
              el => el.facetTitle === nestTitle + '.lvl' + (nestIdxInt - 1)
            )
            parentFacet.facetValues.forEach(parentValue => {
              parentValue.subValues = facet.facetValues
                .filter(
                  el =>
                    el.originalTitle
                      .split(' > ')
                      .slice(0, -1)
                      .join(' > ') === parentValue.originalTitle
                )
                .map(el => {
                  el.parent = parentValue
                  return el
                })
              if (!parentValue.subValues.length) delete parentValue.subValues
            })
          }
        })
        this.searchFacets = grouped
      }
    },
    queryFacetsData (facets) {
      this.searchFacets.forEach(el => {
        const facet = facets[el.facetTitle]
        el.facetValues.forEach(el => {
          el.valueQty =
            facet && facet[el.originalTitle] ? facet[el.originalTitle] : 0
        })
        const sortedFacetValues = [
          ...el.facetValues.filter(el => el.valueQty).sort((a, b) => a.valueTitle.localeCompare(b.valueTitle, 'en', { numeric: true })),
          ...el.facetValues.filter(el => !el.valueQty).sort((a, b) => a.valueTitle.localeCompare(b.valueTitle, 'en', { numeric: true }))
        ]
        el.facetValues = sortedFacetValues
      })
    },
    isMobile: {
      handler () {
        this.isMobile ? this.isSearchFiltersVisible = false : this.isSearchFiltersVisible = true
      },
      immediate: true
    },
    'employeeToggle.value' () {
      this.doSearch()
    }
  },
  computed: {
    ...mapState('search', [
      'initFacetsData',
      'queryFacetsData',
      'productsData',
      'resultsMetaData',
      'defaultHitsPerPage',
      'suggestedProductsData',
      'facetBlackList',
      'scrollPosition'
    ]),
    ...mapGetters('user', ['isEmployee']),
    flatFacetValues () {
      return this.searchFacets.map(el => el.facetValues).flat()
    },
    facetChipsToHide () {
      if (this.showAppliedFacetChips) return []
      const findParentFacetValues = (value, acc) => {
        acc.push(value.indexTitle)
        if (value.parent) findParentFacetValues(value.parent, acc)
        return acc
      }

      return this.flatFacetValues
        .filter(el => this.facetValuesToApply.includes(el.indexTitle))
        .map(el => {
          return findParentFacetValues(el, [])
        })
        .flat()
    },
    facetChips () {
      return this.flatFacetValues.filter(
        el => el.isRefined && !this.facetChipsToHide.includes(el.indexTitle)
      )
    },
    selectedFacetValues () {
      return this.searchFacets
        .map(facet => facet.facetValues.filter(value => value.isRefined))
        .filter(el => el.length)
        .map(value => value.map(el => el.indexTitle))
    },
    visibleSearchFacets () {
      return this.searchFacets.filter(el => el.isVisible).length
        ? this.searchFacets.filter(el => el.isVisible)
        : null
    },
    visibleSearchResults () {
      return this.productsData
    },
    nextPage () {
      return this.resultsMetaData &&
        this.resultsMetaData.page < this.resultsMetaData.totalPages - 1
        ? this.resultsMetaData.page + 1
        : null
    },
    hasClearanceItems () {
      return this.flatFacetValues.some(
        el => el.indexTitle === "'Categories.lvl0':'Clearance'" && el.isVisible
      )
    },
    isClearanceApplied () {
      return this.flatFacetValues.some(
        el => el.indexTitle === "'Categories.lvl0':'Clearance'" && el.isRefined
      )
    }
  },
  methods: {
    ...mapActions('search', ['setSearchResultsData', 'setInitFacetsData', 'setQuerySuggestionsData']),
    ...mapMutations('search', { unsetSuggestedProductsData: 'UNSET_SUGGESTED_PRODUCTS_DATA' }),
    onSortingUpdate () {
      this.doSearch()
    },
    onFacetValueUpdate (value) {
      this.doFacetUpdates(value)
      this.doSearch()
    },
    onSearchQueryUpdate: _.debounce(function () {
      this.doSearch()
    }, 1000),
    handleQueryInput (e) {
      this.searchQuery = e.target.value
      this.showSuggestions = true
    },
    onSearchQueryReset () {
      this.searchQuery = ''
      this.sendQuerySuggestions()
      this.onSearchQueryUpdate()
    },
    onFacetsReset () {
      this.facetChips.forEach(el => {
        if (el.isRefined) this.doFacetUpdates(el)
      })
      this.doSearch()
    },
    onSingleFacetReset (facet) {
      this.facetChips
        .filter(el => {
          const facetNameWithoutLvl = facet.facetTitle.split('.lvl')[0]
          const currentFacetNameWithoutLvl = el.facet.facetTitle.split('.lvl')[0]
          return facetNameWithoutLvl === currentFacetNameWithoutLvl
        })
        .forEach(el => {
          if (el.isRefined) this.doFacetUpdates(el)
        })
      this.doSearch()
    },
    onClearanceClick () {
      const clearanceValue = this.flatFacetValues.find(
        el => el.indexTitle === "'Categories.lvl0':'Clearance'"
      )
      this.onFacetValueUpdate(clearanceValue)
    },
    onItemCategoryClick (value) {
      const facetValue = this.flatFacetValues.find(
        el => el.indexTitle === value
      )
      if (!facetValue.isRefined) {
        this.doFacetUpdates(facetValue)
        this.doSearch()
      }
    },
    toggleSearchFilters () {
      this.isSearchFiltersVisible = !this.isSearchFiltersVisible
    },
    doFacetUpdates (value) {
      value.isRefined = !value.isRefined
      const updateSubValues = value => {
        value.isRefined = false
        if (value.subValues) value.subValues.forEach(updateSubValues)
      }
      const updateParents = value => {
        value.isRefined = false
        value.isOpen = true
        if (value.parent) updateParents(value.parent)
      }

      if (value.isRefined) {
        if (value.subValues) value.subValues.forEach(updateSubValues)
        if (value.parent) updateParents(value.parent)
      }

      if (value.isRefined) value.facet.isOpen = true
    },
    doSearchMore: _.throttle(function () {
      this.$nextTick(() => {
        this.doSearch(this.nextPage)
      })
    }, 1000),
    doSearch (page = 0, hitsPerPage = this.defaultHitsPerPage, requestOptions = { isImpressionsAnalyticsEnabled: true, getPrices: true }) {
      const options = {
        isImpressionsAnalyticsEnabled: typeof requestOptions?.isImpressionsAnalyticsEnabled !== 'undefined'
          ? requestOptions?.isImpressionsAnalyticsEnabled : true,
        getPrices: typeof requestOptions?.getPrices !== 'undefined'
          ? requestOptions?.isImpressionsAnalyticsEnabled : true
      }
      return this.withLoader(() =>
        this.setSearchResultsData({
          sortBy: this.selectedSorting,
          query: this.searchQuery,
          facets: ['*'],
          page,
          hitsPerPage,
          isCatalog: this.isCatalog,
          filters: this.selectedFacetValues,
          isDiscountedPrice: this.employeeToggle.value,
          options
        })
      )
    },
    getFacetsFromUrl () {
      let facets = this.$route.query.facets
      if (facets) {
        facets = facets.split(' AND ').map(el => el.slice(1, -1)).map(el => el.split(' OR ')).flat()
        return facets
      } else {
        return []
      }
    },
    updateUrl () {
      const urlFacetBlackList = [...this.facetBlackList, ...this.facetValuesToApply]
      const allowedFacetsToUrl = this.selectedFacetValues
        .map(facetValues => facetValues.filter(facetValue => !urlFacetBlackList.some(facetName => facetValue.includes(facetName))))
        .filter(el => el.length)
      const facetsString = allowedFacetsToUrl.map(el => `(${el.join(' OR ')})`).join(' AND ')
      const query = {
        facets: facetsString,
        query: this.searchQuery,
        page: this.resultsMetaData.page,
        sorting: this.selectedSorting
      }

      this.$router.replace({ query, params: { key: this.$route.path } }).catch(() => {})
    },
    scrollListener: _.throttle(function () {
      this.headerHeight = this.getHeaderHeight()
      const facetsBoundingClientRect = document.querySelector('.search__facets').getBoundingClientRect()
      this.emulateStickyBehavior = facetsBoundingClientRect.top - this.headerHeight - 20 <= 0 &&
        window.pageYOffset > this.facetsInitialPosition - this.headerHeight - 20
    }, 50),
    closeFacets (toggledFacet) {
      this.visibleSearchFacets.forEach(visibleFacet => {
        if (visibleFacet.isOpen && visibleFacet.facetTitle !== toggledFacet.facetTitle) visibleFacet.isOpen = false
      })
    },
    onSuggestionSelected ({ query, facet }) {
      this.searchQuery = query

      this.facetChips.forEach(el => {
        if (el.isRefined) this.doFacetUpdates(el)
      })

      if (facet) {
        const facetValueToApply = this.searchFacets
          .map(facet => facet.facetValues)
          .flat()
          .find(facetValue => facetValue.originalTitle === facet)
        this.onFacetValueUpdate(facetValueToApply)
      }
      this.onSearchQueryUpdate()
      this.showSuggestions = false
    },
    onQueryInputFocus () {
      this.showSuggestions = true
    },
    onQueryInputBlur () {
      this.showSuggestions = false
    },
    sendQuerySuggestions: _.debounce(function () {
      this.setQuerySuggestionsData({ query: this.searchQuery })
    }, 300)
  },
  async created () {
    this.unsetSuggestedProductsData()

    const facetsFromUrl = this.getFacetsFromUrl()
    const pageFromUrl = +this.$route.query.page || 0
    const nbResults = (pageFromUrl + 1) * this.defaultHitsPerPage
    this.searchQuery = this.queryToApply || ''
    this.selectedSorting = this.$route.query.sorting || this.defaultSorting

    if (!this.initFacetsData) {
      await this.setInitFacetsData()
    }
    this.searchFacets
      .map(el => el.facetValues)
      .flat()
      .filter(el => this.facetValuesToApply.includes(el.indexTitle))
      .forEach(this.doFacetUpdates)

    await this.doSearch(0, 1, { isImpressionsAnalyticsEnabled: false, getPrices: false })
    if (!this.showInitiallyEmptyFacets) {
      this.searchFacets.forEach(facet => {
        facet.facetValues.sort((a, b) => a.valueTitle.localeCompare(b.valueTitle, 'en', { numeric: true }))
        facet.facetValues.forEach(facetValue => {
          facetValue.isVisible = !!facetValue.valueQty
        })
      })
      this.searchFacets.sort((a, b) => b.totalValueQty - a.totalValueQty)
    }

    this.searchFacets
      .map(el => el.facetValues)
      .flat()
      .filter(el => facetsFromUrl.includes(el.indexTitle))
      .forEach(this.doFacetUpdates)
    await this.doSearch(0, nbResults)

    const scrollPosition = this.$route.query.scroll || this.scrollPosition
    if (scrollPosition) this.scrollToPosition(scrollPosition)

    this.$watch('queryFacetsData', this.updateUrl)
    this.$watch('searchQuery', () => {
      this.onSearchQueryUpdate()
      this.sendQuerySuggestions()
    })

    if (!this.isMobile && this.isIE) {
      this.$nextTick(() => {
        window.addEventListener('scroll', this.scrollListener)
        this.facetsInitialPosition = document.querySelector('.search__facets')?.getBoundingClientRect().top + window.pageYOffset
        document.querySelector('.search__facets').style.width = `${document.querySelector('.search__facets-wrap')?.offsetWidth}px`
      })
    }

    this.isInitialized = true
    this.$emit('initialized')
  },
  beforeDestroy () {
    this.setQuerySuggestionsData({ query: '' })
    if (!this.isMobile && this.isIE) window.removeEventListener('scroll', this.scrollListener)
  },
  data () {
    return {
      searchQuery: '',
      searchFacets: [],
      selectedSorting: '',
      isSearchFiltersVisible: true,
      emulateStickyBehavior: false,
      facetsInitialPosition: 0,
      showSuggestions: false,
      employeeToggle: {
        label: 'Only show items with employee discount',
        value: false
      },
      isInitialized: false
    }
  }
}
</script>
