<template>
  <li
    class="facet"
    :class="{ 'is-sticky': facet.isSticky }"
  >
    <h6
      @click="handleFacetToggle"
      class="facet__title"
      :class="{ 'is-open': facet.isOpen }"
    >
      <i class="icon-angle-right"></i>
      {{ facet.displayTitle }}
      {{ this.amountOfSelectedValues ? `(${this.amountOfSelectedValues})` : '' }}
    </h6>
    <template v-if="facet.isOpen">
      <div
        class="facet__value-container"
        :class="{
          'is-open is-scroll': facet.isOpen,
          'all-visible': facet.visibleFacetValues.length <= 10
        }"
      >
        <SearchFacetValue
          v-for="value in visibleValues"
          :key="value.indexTitle"
          :facetValue="value"
          v-on="$listeners"
        />
      </div>
      <div
        v-if="facet.visibleFacetValues.length > 10 || amountOfSelectedValues"
        class="facet__actions"
        :class="{ 'facet__actions--space': facet.visibleFacetValues.length > 10 }"
      >
        <button
          v-if="facet.visibleFacetValues.length > 10"
          @click="isAllValuesVisible = !isAllValuesVisible"
          class="button facet__view-more-btn"
        >{{ isAllValuesVisible ? '- VIEW LESS' : '+ VIEW MORE' }}
        </button>
        <button
          v-if="amountOfSelectedValues"
          @click="$emit('clearFacetSelections', facet)"
          class="search__chips-clear-all button button--outlined"
        >Clear All
        </button>
      </div>
    </template>
  </li>
</template>

<script>
import SearchFacetValue from '@/components/search/SearchFacetValue'

export default {
  name: 'SearchFacetGroup',
  components: {
    SearchFacetValue
  },
  props: ['facet'],
  computed: {
    amountOfSelectedValues () {
      let amount = 0
      const calculateRefinedSubValues = value => {
        if (value.isRefined) amount++
        if (value.subValues) value.subValues.forEach(calculateRefinedSubValues)
      }
      this.facet.visibleFacetValues.forEach(el => {
        if (el.isRefined) amount++
        if (el.subValues) return el.subValues.forEach(calculateRefinedSubValues)
      })
      return amount
    },
    visibleValues () {
      return this.facet.visibleFacetValues.length <= 10
        ? this.facet.visibleFacetValues
        : this.isAllValuesVisible
          ? this.facet.visibleFacetValues
          : this.facet.visibleFacetValues.slice(0, 10)
    }
  },
  methods: {
    handleFacetToggle () {
      this.$emit('facetToggled', this.facet)
      this.facet.isOpen = !this.facet.isOpen
    }
  },
  data () {
    return {
      isAllValuesVisible: this.facet.visibleFacetValues.length <= 10
    }
  }
}
</script>
