<template>
  <div class="search-item">
    <a
      @click.prevent="handleItemClick"
      :href="productData.link"
      :title="productData.title"
      class="search-item__image-container overlay"
    >
      <img
        :src="productData.image.src"
        :alt="productData.title"
        :title="productData.title"
        class="search-item__image overlay--img"
        loading=”lazy”
      />
    </a>
    <div class="search-item__container">
      <div
        v-if="productData.discontinued"
        class="search-item__discontinued"
      >
        Discontinued
      </div>
      <a
        @click.prevent="handleItemClick"
        :href="productData.link"
        :title="productData.title"
        class="search-item__title"
      >
        <h5 v-html="productData.title"></h5>
      </a>
      <div v-if="productData.brand" class="search-item__brand" v-html="productData.brand"></div>
      <div class="search-item__categories-wrap" v-if="productData.groupedCategories && showItemCategories">
        <span
          @click="isCategoryOpened = !isCategoryOpened"
          class="search-item__categories-toggle"
          :class="{ 'search-item__categories-toggle--active': isCategoryOpened }"
        >
          <span>{{ isCategoryOpened ? 'Hide Categories' : 'Show Categories' }}</span>
          <i class="icon-angle-right"></i>
        </span>
        <ul class="search-item__categories" v-if="isCategoryOpened" :class="{active: isCategoryOpened}">
          <li class="search-item__categories-item"
            v-for="(group, idx) in productData.groupedCategories"
            :key="idx"
          >
          <template v-for="(category, idx) in group" >
            <span :key="category.indexTitle + 'separator'" v-if="idx > 0"> > </span>
            <span :key="category.indexTitle" class="search-item__categories-link" @click="$emit('itemCategoryClicked', category.indexTitle)" v-html="category.title"></span>
          </template>
          </li>
        </ul>
      </div>

      <p class="search-item__desc" v-if="productData.catalogId" v-html="productData.catalogId"></p>
      <p class="search-item__desc" v-if="productData.descriptionShort" v-html="productData.descriptionShort"></p>
      <template v-if="productData.hasFinalizedPrices">
        <div class="search-item__price">
          <ProductPrice :productData="productData" :showPriceLabels="true"/>
        </div>
        <div class="search-item__actions" v-if="productData.hasPrice">
          <div class="search-item__desc search-item__desc--incremental" v-if="productData.minOrderQty > 1">
            Incremental Order Quantity: {{ productData.minOrderQty }}
            <i class="icon-question-circle search-item__moq-tooltip" v-tooltip="`This item has an incremental order quantity of ${productData.minOrderQty}; <br/> typing in a quantity value may result in rounding`" />
          </div>
          <div class="search-item__actions-wrap">
            <ProductQuantityInput
                :minOrderQty="productData.minOrderQty"
                v-model="addToCartQty"
              />
            <button
              type="button"
              @click="$emit('addProductToCart', addToCartQty)"
              class="search-item__button--cart button button--primary button--cart"
            >
              <i class="icon-shopping-cart"></i>
              Add to Cart
            </button>
          </div>
        </div>
      </template>
      <CompareCheckbox
        v-if="isComparable"
        :value="isCompareSelected"
        :checkboxField="{ label: productData.sku }"
        :activeState="isCompareSelected"
        :disabledState="!isCompareSelected && productsToCompare.length === maxProductsToCompare"
        @input="handleCompareClick(productData)"
      />
      <AddToProductsList
        v-if="showFavoritesIcon"
        :productData="productData"
        :position="index + 1"
        :isFavoritesIconMode="true"
        :analyticsData="{referencePage: isCatalog ? 'Category' : 'Search Results'}"
        class="product-details__add-to-products-list"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import NavigationalMixin from '@/mixins/NavigationalMixin'
import ProductsCompareOverlayMixin from '@/mixins/ProductsCompareOverlayMixin'
import ProductPrice from '@/components/ProductPrice'
import ProductQuantityInput from '@/components/ProductQuantityInput'
import CompareCheckbox from '@/components/CompareCheckbox'
import AddToProductsList from '@/components/AddToProductsList'

export default {
  name: 'SearchItem',
  components: {
    ProductPrice,
    ProductQuantityInput,
    CompareCheckbox,
    AddToProductsList
  },
  mixins: [NavigationalMixin, ProductsCompareOverlayMixin],
  props: {
    productData: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    showItemCategories: {
      type: Boolean,
      default: false
    },
    isComparable: {
      type: Boolean,
      default: false
    },
    showFavoritesIcon: {
      type: Boolean,
      default: true
    },
    populateAnalytics: {
      type: Boolean,
      default: false
    },
    searchQuery: String,
    isCatalog: Boolean,
    catalogOriginalTitle: String
  },
  computed: {
    ...mapState('search', ['resultsMetaData'])
  },
  methods: {
    ...mapMutations('product', { setProductAnalyticsData: 'SET_PRODUCT_ANALYTICS_DATA' }),
    ...mapActions('analytics', ['sendSearchTracking']),
    handleItemClick () {
      if (this.populateAnalytics) {
        this.sendSearchTracking({
          objectID: this.productData.sku,
          position: this.index,
          indexName: this.resultsMetaData?.indexName,
          queryID: this.resultsMetaData?.queryID
        })
        if (!this.productData.link.includes('http')) {
          this.setProductAnalyticsData({
            referencePage: this.isCatalog ? 'Category' : 'Search Results',
            catalogOriginalTitle: this.catalogOriginalTitle,
            position: this.index + 1,
            searchQuery: this.searchQuery,
            indexName: this.resultsMetaData?.indexName,
            queryID: this.resultsMetaData?.queryID
          })
        }
      }
      this.navigateTo(this.productData.link)
    }
  },
  created () {
    this.addToCartQty = this.productData.minOrderQty
  },
  data () {
    return {
      addToCartQty: 1,
      isCategoryOpened: false
    }
  }
}
</script>
